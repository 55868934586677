import React, { Component } from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout.js";
import styled from "styled-components";

class PostTemplate extends Component {
  render() {
    const post = this.props.data.wordpressPost;
    // const resolutions = post.featured_media.localFile ? post.featured_media.localFile.childImageSharp.resolutions : ''
    const alt_text = post.featured_media && post.featured_media.alt_text ? post.featured_media.alt_text : `Unknown post image`;
    return (
      <Layout 
        title={post.yoast_meta.yoast_wpseo_title}
        metaDescription={post.yoast_meta.yoast_wpseo_metadesc}
        pageImageURL={post.featured_media.localFile.childImageSharp.fixed.src}
      >
        <Heading dangerouslySetInnerHTML={{ __html: post.title }} />
        <Subtitle><span>by {post.author.name}</span> &#183; <i>{post.date}</i></Subtitle>

        {post.featured_media !== null &&
          <ImageContainer>
            <img src={post.featured_media.localFile.childImageSharp.fixed.src} alt={`${alt_text}`} />
          </ImageContainer>
        }

        <PostText dangerouslySetInnerHTML={{ __html: post.content }}></PostText>

      </Layout>
    )
  }
}

// PostTemplate.propTypes = {
//   data: PropTypes.object.isRequired,
//   edges: PropTypes.array,
// }

export default PostTemplate

export const postQuery = graphql`
  query PostDetails($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      content
      excerpt
      date(formatString: "MMMM DD, YYYY")
      author {
        name
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            fixed(height: 800) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;

const Heading = styled.h1`
  margin: 0;
  margin-top: 50px;
`;

const Subtitle = styled.p`
  margin: 0;
  margin-top: 8px;
  font-size: 0.9em;
`;

const ImageContainer = styled.div`
  margin: 50px 0;
  text-align: center;
  max-width: 100%;
    img {
      width: auto;
      max-width: 100%;
      height: auto;
    }
`;

const PostText = styled.div`
  line-height: 2em;
`;